import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { query, collection, where, or } from "@firebase/firestore";
import { useLoggedInState } from "~/lib/useLoggedInState";
import { converters } from "~/lib/converter";
import { DocStatuses } from "@converge-collective/common/models/DocMeta";
import { WithRef } from "@converge-collective/common/models/Base";
import { Network } from "@converge-collective/common/models/Network";

export function useNetworkGroups(all?: boolean) {
  const firestore = useFirestore();
  const { network } = useLoggedInState();

  const activeGrpsQuery = (network: WithRef<Network>) =>
    query(
      collection(network.ref, "groups"),
      or(
        where("latestDocUpdate.status", "==", DocStatuses.Published),
        where("latestDocUpdate.status", "==", DocStatuses.Active)
      )
    );

  const allGrpsQuery = (network: WithRef<Network>) =>
    query(collection(network.ref, "groups"));

  // Firebase query
  const groupsQuery = query(
    (network
      ? all
        ? allGrpsQuery(network)
        : activeGrpsQuery(network)
      : collection(firestore, "noop")
    ).withConverter(converters.group.read)
  );
  // Firestore data
  const { data, status } = useFirestoreCollectionData(groupsQuery);

  return {
    groups: data,
    status,
  };
}
