import { SxProps, Theme } from "@mui/material";
import {
  DateTimePickerProps,
  DateTimePicker as MuiDateTimePicker,
  PickersActionBarProps,
} from "@mui/x-date-pickers";
import { merge } from "lodash";
import { mergeSx } from "merge-sx";

// TODO re-enable once date-time-tz supports date-fns v3
// import { formatTimezoneWithDefault } from "~/lib/browser-util";

// type DTPProps<D> = DateTimePickerProps<D> & {};

/** Wraps MUI to show the timezone in an input adornment */
export function TZDateTimePicker(props: DateTimePickerProps<Date>) {
  // const { profile } = useLoggedInState();
  const InputProps = {
    // startAdornment: (
    //   <Tooltip title={formatTimezoneWithDefault(profile?.timezone, "zzzz")}>
    //     <Chip
    //       variant="outlined"
    //       sx={{ mr: 1 }}
    //       size="small"
    //       label={formatTimezoneWithDefault(profile?.timezone, "z")}
    //     />
    //   </Tooltip>
    // ),
  };

  const actionBar = props.slotProps?.actionBar;
  // not sure why we have to cast as PickersActionBarProps :/
  const actions = actionBar && (actionBar as PickersActionBarProps).actions;
  const slotProps = merge(props.slotProps, {
    textField: {
      InputProps,
    },
    actionBar: {
      actions: actions ? actions : ["accept"],
    },
  });

  const sx: SxProps = mergeSx(props.sx, (theme: Theme) => ({
    "& .Mui-focused .MuiChip-root": {
      borderColor: theme.palette.primary.main,
    },
  }));

  // const value = dayjs.tz(
  //   props.value as unknown as Date,
  //   profile?.timezone || "America/Denver"
  // );

  // const dayjsProps: DateTimePickerProps<Dayjs> = {
  //   ...props,
  //   value: dayjs(props.value),
  //   onChange: (date, context) => {
  //     if (date) props.onChange(date.toDate(), context);
  //     else props.onChange(null, context);
  //   },
  // };

  return <MuiDateTimePicker {...props} slotProps={slotProps} sx={sx} />;
}
