import { NanoProfile } from "@converge-collective/common/models/Profile";
import { Team } from "@converge-collective/common/models/Team";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import {
  Chip,
  Stack,
  SwipeableDrawer,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useUser } from "reactfire";
import { profileRoute } from "~/routes";
import { ExpandedProfile } from "./ExpandedProfile";

export type ProfilePreviewProps = {
  profile: NanoProfile;
  title?: string;
  team?: Team | null;
  children?: React.ReactNode;
  openAutomatically?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  usePreviewPane?: boolean;
  sx?: SxProps;
};

/**
 * the profile passed in is a NanoProfile often from some denoramlized source
 * like group membership, team membership, etc
 */
export default function ProfilePreview({
  profile,
  title,
  team,
  children,
  wrapperStyle,
  onClose,
  onOpen,
  openAutomatically = false,
  usePreviewPane = true,
}: ProfilePreviewProps & {
  wrapperStyle?: React.CSSProperties;
}): React.ReactElement {
  const router = useRouter();
  const { data: user } = useUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(openAutomatically);

  const handleOpen = () => {
    // only support expanded profile for logged in users
    if (user) {
      if (usePreviewPane) {
        onOpen?.();
        console.log("ProfilePreview handle open", { profile, team });
        setIsDrawerOpen(true);
      } else {
        router.push(profileRoute(profile.id));
      }
    }
  };
  const handleClose = () => {
    console.log("handle close");
    setIsDrawerOpen(false);
    onClose && onClose();
  };

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const anchor = isSmDown ? "bottom" : "right";

  const drawerStyle = isSmDown
    ? { height: "76%", "& .MuiDrawer-paper": { height: "76%" } }
    : {
        maxWidth: "60%",
        "& .MuiDrawer-paper": {
          width: "60%",
          height: "100%",
          overflow: "scroll",
        },
      };

  if (!profile) {
    console.warn("unexpected missing profile in ProfilePreview", {
      profile,
      title,
      team,
    });
    return <div />;
  }
  return (
    <>
      {children ? (
        <div
          style={
            wrapperStyle || {
              cursor: "pointer",
            }
          }
          onClick={handleOpen}
        >
          {children}
        </div>
      ) : (
        <Stack direction="row" alignItems="center" onClick={handleOpen}>
          <Typography
            sx={{
              display: "inline",
              cursor: "pointer",
            }}
          >
            <strong>{title || profile.name}</strong>
          </Typography>
          {team && (
            <Chip
              sx={{ ml: 1, height: 20 }}
              icon={<Diversity3Icon />}
              label={team.name}
              size="small"
              color="primary"
            />
          )}
        </Stack>
      )}
      <SwipeableDrawer
        sx={{
          ...drawerStyle,
          zIndex: 10000,
        }}
        anchor={anchor}
        open={isDrawerOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            handleClose();
          }
        }}
      >
        {user && isDrawerOpen && (
          <ExpandedProfile
            sx={{ px: 2 }}
            setIsDrawerOpen={setIsDrawerOpen}
            team={team}
            profile={profile}
          />
        )}
      </SwipeableDrawer>
    </>
  );
}
