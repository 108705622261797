import {
  imageSize,
  SupportedImageSize,
} from "@converge-collective/common/util";
import { CSSProperties, useState } from "react";

/**
 * An image that supports standard sizes with support for falling back to the
 * original image if the size is not available.
 */
export default function Img({
  src,
  size,
  style,
  className,
  alt,
}: {
  src: string;
  size: SupportedImageSize;
  className?: string;
  style?: CSSProperties;
  alt?: string;
}): React.ReactElement {
  const [attempt, setAttempt] = useState(0);

  const onError = ({ currentTarget }) => {
    // prevent looping
    setAttempt(attempt + 1);
    if (attempt > 1) return;
    currentTarget.onerror = null; // prevents looping
    // if there's an error, use the original image
    currentTarget.src = src;
  };

  const sizedImageUrl = imageSize(src, size);
  return (
    <img
      alt={alt || ""}
      src={sizedImageUrl || src}
      style={style}
      className={className}
      onError={onError}
    />
  );
}
