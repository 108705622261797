import { LiteGroup } from "@converge-collective/common/models/Group";
import { Autocomplete, SxProps, TextField, Typography } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { isArray, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useMyGroups } from "~/hooks/useMyGroups";
import { useNetworkGroups } from "~/hooks/useNetworkGroups";

type Props = {
  onSetGroups: (groups: LiteGroup[] | null) => void;
  multiple?: boolean;
  required?: boolean;
  label?: string;
  helperText?: string;
  initSelected?: LiteGroup[];
  sx?: SxProps;
  variant?: "outlined" | "standard" | "filled";
  onlyMyGroups?: boolean;
};

export default function AutocompleteGroup({
  onSetGroups,
  multiple = false,
  required = true,
  helperText = "",
  label,
  initSelected,
  sx,
  variant = "outlined",
  onlyMyGroups,
}: Props): React.ReactElement {
  const { groups, status } = useNetworkGroups();
  const { groups: myGroups = [], status: myGroupStatus } = useMyGroups();
  const [selectedGroups, setSelectedGroups] = useState<LiteGroup[] | null>(
    initSelected || null
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!!initSelected && initSelected !== undefined) {
      setSelectedGroups(initSelected);
    }
  }, [initSelected]);

  useEffect(() => {
    if (required && isEmpty(selectedGroups)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectedGroups, required]);

  const loading = status === "loading" || myGroupStatus === "loading";
  const isError = status === "error" || myGroupStatus === "error";
  const userGroups = loading || isError ? [] : onlyMyGroups ? myGroups : groups;

  return (
    <Autocomplete
      sx={sx}
      openOnFocus
      autoComplete
      autoSelect={false}
      multiple={multiple}
      autoHighlight
      loading={loading}
      options={userGroups}
      value={selectedGroups || []}
      getOptionLabel={(group: LiteGroup) => {
        if (!group || isEmpty(group)) {
          return "";
        }
        return isArray(group) ? group[0].name : group.name;
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, newValue) => {
        console.log("set group", { newValue });
        const groups = newValue
          ? isArray(newValue)
            ? newValue
            : [newValue]
          : null;
        setSelectedGroups(groups);
        onSetGroups(groups);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label ? label : multiple ? "Groups" : "Group"}
          helperText={error ? "This field is required" : helperText}
          fullWidth
          variant={variant}
          error={error || isError}
        />
      )}
      renderOption={(props, option: LiteGroup, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);

        return (
          <li
            {...props}
            key={option.id}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              {parts.map((part, index: number) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
            <div style={{ color: "gray" }}>
              <Typography variant="overline"></Typography>
            </div>
          </li>
        );
      }}
    />
  );
}
