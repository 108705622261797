import dynamic from "next/dynamic";

/**
 * Use this component instead of importing `MuiTipTap` directly to lazy load
 * since it's a large dep
 */
const Editor = dynamic(() => import("./MuiTipTap"), {
  ssr: false,
});

export default Editor;
