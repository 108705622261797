import { WithRef } from "@converge-collective/common/models/Base";
import {
  Network,
  NetworkMembership,
} from "@converge-collective/common/models/Network";
import { NanoProfile } from "@converge-collective/common/models/Profile";
import { Chip, Stack, SxProps, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

import {
  algoliaConfig,
  algoliaNetworkFilter,
  algoliaSearchClient,
  prefixedIndex,
} from "~/lib/algolia";
import UserAvatar from "./UserAvatar";

type Props = {
  label: string;
  helperText?: string;
  sx?: SxProps;
  variant?: "standard" | "outlined" | "filled";
  selectedIds?: string[];
  // set this to control from the parent
  selected?: NanoProfile[] | null;
  onUserSelected?: (p: NanoProfile[]) => void;
  removeIds?: string[];
  multiple?: boolean;
  handleBlur?: () => void;
} & (
  | {
      // network to fetch from
      network: WithRef<Network>;
    }
  | {
      profiles: NanoProfile[];
    }
);

const config = algoliaConfig();
const searchClient = algoliaSearchClient(config);

export default function UserAutocomplete({
  label,
  helperText,
  sx,
  onUserSelected,
  multiple = false,
  variant = "outlined",
  selectedIds,
  removeIds,
  handleBlur,
  ...rest
}: Props): React.ReactElement {
  const propsProfiles = "profiles" in rest ? rest.profiles || [] : [];
  const selectedProfiles = rest.selected || [];

  const [profiles, setProfiles] = useState<NanoProfile[]>(propsProfiles);
  const [profilesData, setProfilesData] = useState<NanoProfile[]>([]);
  const [selected, setSelected] = useState<NanoProfile[] | null>(
    selectedProfiles
  );
  const [query, setQuery] = useState<string>("");

  const profilesDataIds = (profilesData || []).map((p) => p.id).join(",");

  const getProfiles = async (query: string) => {
    const hasNetwork = "network" in rest;
    if (!hasNetwork) return [];
    const network = rest.network;

    const networkFilter = algoliaNetworkFilter(network);
    const members = await searchClient.searchSingleIndex<NetworkMembership>({
      indexName: prefixedIndex("network_members"),
      searchParams: { query: query, hitsPerPage: 16, filters: networkFilter },
    });

    const profilesHits = members.hits
      .filter((m) => !isEmpty(m.profile?.name))
      .map((m) => m.profile);

    console.log("algolia user results", { profilesHits });

    setProfilesData(profilesHits);
  };
  useEffect(() => {
    getProfiles("");
  }, []);

  useEffect(() => {
    if (propsProfiles.length > 0) setProfiles(propsProfiles);
  }, [propsProfiles]);

  useEffect(() => {
    if (selectedProfiles.length > 0) setSelected(selectedProfiles);
  }, [selectedProfiles]);

  useEffect(() => {
    if (isEmpty(profilesData)) return;
    // filter out profiles without a name or if it's in removeIds
    const profilesWithNames = profilesData.filter(
      (p) => p.name && !(removeIds || []).includes(p.id)
    );
    setProfiles(profilesWithNames);

    // NOTE: reverted this change because it caused multi user autocomplete to
    // not work (can't start a DM with multiple people):
    //github.com/converge-collective/web/pull/1230/files#diff-a902452f725682e136f01aa0c5f65f65c2a5a433c7520ff53ad4141eefec010f
    if (!isEmpty(selectedIds)) {
      const selectedProfiles = profilesData.filter((p) =>
        (selectedIds || []).includes(p.id)
      );

      if (multiple) {
        // TODO - don't set multiple if multiple is not true!!!!
        setSelected(selectedProfiles);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, selectedIds, profilesDataIds, removeIds]);

  const loading = profiles.length === 0;

  return (
    <>
      <Autocomplete
        sx={sx}
        openOnFocus
        autoComplete
        onBlur={handleBlur}
        autoHighlight
        loading={loading}
        options={profiles}
        getOptionLabel={(p: NanoProfile) => p.name || ""}
        filterSelectedOptions={true}
        value={selected}
        isOptionEqualToValue={(
          option: NanoProfile | NanoProfile[],
          value: NanoProfile | NanoProfile[]
        ) => {
          const firstOption = Array.isArray(option) ? option[0] : option;
          const firstValue = Array.isArray(value) ? value[0] : value;
          return firstOption?.id === firstValue?.id;
        }}
        onChange={(
          _,
          newValue: WithRef<NanoProfile> | WithRef<NanoProfile>[]
        ) => {
          console.log("UserAutocomplete onChange", { newValue });
          const selectedProfiles = Array.isArray(newValue)
            ? newValue
            : [newValue];
          setSelected(selectedProfiles);
          setQuery("");
          onUserSelected && onUserSelected(selectedProfiles);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helperText}
            variant={variant}
            fullWidth={true}
            label={label}
            value={query}
            onChange={(e) => {
              const value = e.target.value;
              setQuery(value);
              getProfiles(value);
            }}
          />
        )}
        multiple={multiple}
        renderTags={(values: NanoProfile[], getTagProps) => {
          return values
            .filter((nm) => nm)
            .map((nm: NanoProfile, index: number) => (
              <Chip
                label={nm.name}
                {...getTagProps({ index })}
                icon={
                  <UserAvatar sx={{ width: 24, height: 24 }} profile={nm} />
                }
                key={index}
              />
            ));
        }}
        renderOption={(props, option: NanoProfile, { inputValue }) => {
          const matches = match(option.name, inputValue, {
            insideWords: true,
            findAllOccurrences: true,
          });
          const parts = parse(option.name, matches);

          return (
            <Stack
              direction="row"
              spacing={1.2}
              component="li"
              {...props}
              key={option.id}
            >
              <UserAvatar sx={{ width: 24, height: 24 }} profile={option} />
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </Stack>
          );
        }}
      />
    </>
  );
}
