import { converters } from "~/lib/converter";
import { WithRef } from "@converge-collective/common/models/Base";
import { Doc } from "@converge-collective/common/models/Doc";
import { DocStatuses } from "@converge-collective/common/models/DocMeta";
import { formatDateTime } from "@converge-collective/common/util";
import { PublishedWithChanges } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { collection, query, where } from "firebase/firestore";
import { useFirestoreCollectionData } from "reactfire";
import { useLoggedInState } from "~/lib/useLoggedInState";

export function DocReverifyRequest({
  doc,
  onEdit,
  isExpanded,
  showReverifyButton = true,
  showVerifiedState = false,
}: {
  doc: WithRef<Doc>;
  isExpanded: boolean;
  onEdit?: () => void;
  showReverifyButton?: boolean;
  showVerifiedState?: boolean;
}) {
  const { profile, isNetworkAdmin } = useLoggedInState();

  const isOwner =
    profile &&
    (doc.createdBy.id === profile.id ||
      doc.owners?.find((o) => o.id === profile.id));

  const isAdminOrOwner = isNetworkAdmin || isOwner;

  // If the user is either:
  // - a doc owner owner
  // - a network admin
  // Then show all doc requests.
  //
  // Otherwise only show doc requests that the user has made (but hide the
  // re-verify button)

  const whereClauses = [
    where("latestDocUpdate.status", "==", DocStatuses.PendingApproval),
    ...(isAdminOrOwner ? [] : [where("requester.id", "==", profile?.id)]),
  ];

  const docReqsQuery = query.apply(null, [
    collection(doc.ref, "docRequests").withConverter(
      converters.docRequest.read
    ),
    ...(whereClauses as any),
  ]);

  const { data: docRequests = [] } = useFirestoreCollectionData(docReqsQuery);
  return (
    <>
      {docRequests.map((docRequest) => (
        <Alert
          icon={false}
          action={
            isAdminOrOwner &&
            showReverifyButton && (
              <Tooltip title="Reverify Doc">
                {isExpanded ? (
                  <Button
                    onClick={onEdit}
                    startIcon={<PublishedWithChanges />}
                    color="inherit"
                    size="small"
                  >
                    Reverify
                  </Button>
                ) : (
                  <IconButton onClick={onEdit} size="small" color="inherit">
                    <PublishedWithChanges />
                  </IconButton>
                )}
              </Tooltip>
            )
          }
          sx={{ my: 2 }}
          key={docRequest.id}
          severity="warning"
        >
          <b>{docRequest.requester.name}</b> requested reverification of this
          doc at{" "}
          <time
            style={{ fontWeight: "bold" }}
            dateTime={formatDateTime(
              docRequest.latestDocUpdate.date
            ).toString()}
          >
            {formatDateTime(docRequest.latestDocUpdate.date)}
          </time>
          .
          {docRequest.note && (
            <Box
              sx={{
                borderLeft: "2px solid",
                borderColor: "warning.main",
              }}
            >
              <blockquote style={{ paddingLeft: 0, marginLeft: 10 }}>
                {docRequest.note}
              </blockquote>
            </Box>
          )}
        </Alert>
      ))}
    </>
  );
}
