import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useState } from "react";

export default function ConfirmDialog({
  isOpen,
  confirmTitle,
  proceedWithAction,
  onSuccess,
  confirmButtonTitle,
  close,
  severity = "error",
  icon,
  children,
  maxWidth = "md",
}: {
  isOpen: boolean;
  confirmTitle: string;
  confirmButtonTitle: string;
  proceedWithAction: () => Promise<void>;
  onSuccess: () => void;
  close: () => void;
  severity?: AlertColor;
  icon?: React.ReactNode;
  children?: React.ReactNode | string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}): React.ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [errMessage, setErrMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const proceed = async () => {
    setIsLoading(true);

    try {
      await proceedWithAction();
      onSuccess();
    } catch (e) {
      console.error(e);
      setErrMessage(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setErrMessage("");
    close();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth
    >
      <Alert icon={icon} sx={{ p: 4 }} severity={severity}>
        <AlertTitle>{confirmTitle}</AlertTitle>
      </Alert>

      {children ? <DialogContent>{children}</DialogContent> : null}
      {!isEmpty(errMessage) && (
        <DialogContent>
          <DialogContentText>
            <Alert severity={"error"} sx={{ mt: 2 }}>
              <AlertTitle>{errMessage}</AlertTitle>
              <Button color={severity} onClick={proceed}>
                Try again
              </Button>
            </Alert>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button color={severity} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={proceed}
          variant="contained"
          color={severity}
        >
          {confirmButtonTitle}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
