import { Box, SxProps, Theme } from "@mui/material";
import Linkify from "linkify-react";
import { RenderHTML } from "./RenderHTML";

export default function AutoLink({
  text,
  sx,
  enableUnfurls = false,
}: {
  text: string | undefined;
  sx?: SxProps<Theme>;
  enableUnfurls?: boolean;
}): React.ReactElement {
  if (!text) return <span />;
  return (
    <Box sx={sx}>
      <Linkify
        options={{
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        }}
      >
        <RenderHTML html={text} enableUnfurls={enableUnfurls} />
      </Linkify>
    </Box>
  );
}
