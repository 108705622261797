import {
  WithCreatedAt,
  WithRef,
} from "@converge-collective/common/models/Base";
import { Doc } from "@converge-collective/common/models/Doc";
import {
  DocStatuses,
  DocUpdate,
} from "@converge-collective/common/models/DocMeta";
import { Network } from "@converge-collective/common/models/Network";
import { PostComment } from "@converge-collective/common/models/Post";
import {
  NanoProfile,
  nanoProfile,
} from "@converge-collective/common/models/Profile";
import { Tag } from "@converge-collective/common/models/Tag";
import { Check, Close, PublishedWithChanges } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { isEmpty, kebabCase } from "lodash";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useUser } from "reactfire";
import useProfile from "~/hooks/useProfile";
import { AuthorizedActions, AuthorizedActionTypes } from "~/lib/authz";
import { converters } from "~/lib/converter";
import { FolderNodeInfo } from "~/lib/folder";
import { createAlert } from "~/lib/globalAlerts";
import { docDetailRoute } from "~/routes";
import { AutocompleteFolder } from "../AutocompleteFolder";
import AutocompleteTag from "../AutocompleteTag";
import Uploader from "../Uploader";
import { DocReverifyRequest } from "../documents/DocReverifyRequest";
import Editor from "../editor/Editor";
import UserAutocomplete from "../user/UserAutocomplete";

export function CreateOrEditDocumentDialog({
  network,
  isOpen,
  onClose,
  docToEdit,
  isJob = false,
  isQAndA = false,
  isReverify = false,
  initBody,
  originalFileUrl,
  selectedFolderId = null,
}: {
  network: WithRef<Network>;
  isOpen: boolean;
  onClose: () => void;
  docToEdit?: WithRef<Doc>;
  isJob?: boolean;
  isQAndA?: boolean;
  isReverify?: boolean;
  initBody?: string;
  originalFileUrl?: string;
  selectedFolderId?: string | null;
}): React.ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { data: user } = useUser();
  const [profile] = useProfile(user?.uid);

  // used to copy authz grants to the new doc
  const [folderInfo, setFolderInfo] = useState<FolderNodeInfo | null>(null);

  const [reverifyNote, setReverifyNote] = useState("");
  const [name, setName] = useState(docToEdit?.name ?? "");
  const [contentHtml, setContentHtml] = useState(docToEdit?.contentHtml ?? "");
  // NOTE: An isJob=true doc currently doesn't have any tags
  const [tags, setTags] = useState<WithRef<Tag>[]>(docToEdit?.tags ?? []);
  const [docOwners, setDocOwners] = useState<NanoProfile[]>(
    docToEdit?.owners ?? []
  );

  useEffect(() => {
    if (profile && isEmpty(docOwners)) {
      setDocOwners([nanoProfile(profile)]);
    }
  }, [docOwners, profile]);

  const defaultVerificationPeriodDays = 365;

  const [photosUploading, setPhotosUploading] = useState(false);
  const [frequency, setFrequency] = useState(
    docToEdit ? docToEdit.verificationPeriodDays : defaultVerificationPeriodDays
  );
  const [coverPhotoUrl, setCoverPhotoUrl] = useState<string | undefined>(
    docToEdit?.coverPhotoUrl
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const MAX_LENGTH = 32;

  const reset = () => {
    setName("");
    setContentHtml("");
    setTags([]);
    setCoverPhotoUrl("");
    setDocOwners([]);
    setFrequency(defaultVerificationPeriodDays);
    setValidationErrors([]);
    setReverifyNote("");
    setFolderInfo(null);
  };

  const markPendingDocRequestsApproved = async () => {
    if (docToEdit && profile) {
      const docRequestsQuery = query(
        collection(docToEdit.ref, "docRequests").withConverter(
          converters.docRequest.read
        ),
        where("latestDocUpdate.status", "==", DocStatuses.PendingApproval)
      );
      const { docs: docRequests } = await getDocs(docRequestsQuery);
      const description = `${profile.name} reverified this doc${
        !isEmpty(reverifyNote) && `: ${reverifyNote}`
      }`;
      const date = new Date();

      const comment: PostComment = {
        profile: nanoProfile(profile),
        date,
        body: description,
      };

      console.log("markPendingDocRequestsApproved", {
        reverifyNote,
        description,
        comment,
        docToEdit,
      });

      await Promise.all(
        docRequests.map(async (docRequest) => {
          const docUpdate: DocUpdate = {
            actor: nanoProfile(profile),
            status: DocStatuses.Approved,
            description,
            date,
          };
          await updateDoc(docRequest.ref, {
            latestDocUpdate: docUpdate,
            docUpdateLog: arrayUnion(docUpdate),
          });

          // Post a Comment on the original doc request Post, whose ID is the same
          // as the doc request ID
          const docRequestPostRef = doc(docToEdit.ref, "posts", docRequest.id);
          await addDoc(collection(docRequestPostRef, "comments"), comment);
        })
      );
    }
  };

  const entityType = isJob ? "Job" : isQAndA ? "Q&A" : "Document";

  const handleSubmit = async () => {
    if (!profile) {
      console.error("No profile found");
      return;
    }

    if (isEmpty(name)) {
      setValidationErrors([
        isQAndA
          ? "Please provide a Question"
          : `Please provide a ${entityType} Name`,
      ]);
      return;
    }
    if (name.length > MAX_LENGTH) {
      setValidationErrors([
        isQAndA
          ? `Question cannot be longer than ${MAX_LENGTH} characters`
          : `${entityType} Name cannot be longer than ${MAX_LENGTH} characters`,
      ]);
      return;
    }

    if (!folderInfo) {
      setValidationErrors(["Please select a Folder"]);
      return;
    }

    // no longer require document content being non-empty because files don't
    // have content
    // if (!isJob && isEmpty(contentHtml)) {
    //   setValidationErrors([`Please provide ${entityType} Content`]);
    //   return;
    // }

    // if (!isJob && isEmpty(coverPhotoUrl)) {
    //   setValidationErrors(["Please provide a Cover Photo"]);
    //   return;
    // }

    if (!isJob && isEmpty(docOwners)) {
      setValidationErrors([`Please provide ${entityType} Owners`]);
      return;
    }

    setIsSubmitting(true);
    setValidationErrors([]);
    const slug = docToEdit?.slug || kebabCase(name);
    const paths = [folderInfo.slug] as [string, ...string[]];

    // console.log("Doc html", { contentHtml });
    if (docToEdit) {
      // save existing doc
      await updateDoc(docToEdit.ref.withConverter(converters.doc.write), {
        ...{
          name,
          contentHtml,
          tags,
          authzGrants: folderInfo.root.authzGrants,
          tagIds: tags.map((t) => t.id),
          coverPhotoUrl: coverPhotoUrl,
          verificationPeriodDays: frequency,
          owners: docOwners,
          docUpdateLog: arrayUnion({
            actor: nanoProfile(profile),
            status: DocStatuses.Active,
            description: `${profile.name} updated this ${entityType}`,
            date: new Date(),
            entity: network,
          }),
        },
        ...(folderInfo && {
          folderPaths: paths,
        }),
      });
      // clear out any pending doc requests
      await markPendingDocRequestsApproved();
    } else {
      const id = doc(collection(network.ref, "docs")).id;
      const update: DocUpdate = {
        actor: nanoProfile(profile),
        status: DocStatuses.Active,
        description: `${profile.name} created this ${entityType}`,
        date: new Date(),
      };

      const newDoc: WithCreatedAt<Doc> = {
        name,
        contentHtml,
        tags,
        folderPaths: paths,
        authzGrants: folderInfo.root.authzGrants,
        tagIds: tags.map((t) => t.id),
        network,
        verificationPeriodDays: frequency,
        coverPhotoUrl: coverPhotoUrl,
        createdAt: new Date(),
        createdBy: nanoProfile(profile),
        slug,
        isJob: Boolean(isJob),
        owners: docOwners,
        originalFileUrl: originalFileUrl || "",
        latestDocUpdate: update,
      };
      await setDoc(
        doc(collection(network.ref, "docs"), id).withConverter(
          converters.doc.write
        ),
        newDoc
      );
    }

    setIsSubmitting(false);

    const message = docToEdit
      ? `${entityType} saved`
      : `${entityType} created` + ": ";
    const link = (
      <Link
        style={{ color: "inherit", fontWeight: "bold" }}
        href={docDetailRoute(network.slug, slug)}
      >
        {name}
      </Link>
    );

    createAlert(
      {
        message: (
          <>
            {message} {link}
          </>
        ),
      },
      { autoHideDuration: 8000 }
    );

    reset();
    onClose();
  };

  const title = isReverify
    ? `Reverify ${entityType}`
    : docToEdit
      ? `Edit ${entityType}`
      : `Create ${entityType}`;

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (docToEdit?.contentHtml || initBody) {
      const html = docToEdit?.contentHtml || initBody || "";
      // console.log("set initial content html", { html });
      setContentHtml(html);
    }
  }, [docToEdit, initBody]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isReverify && (
          <>
            <Alert
              variant="outlined"
              sx={{
                my: 1,
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
              severity="info"
            >
              <AlertTitle>
                Please ensure the document is still valid and up-to-date.
              </AlertTitle>
              {docToEdit && (
                <DocReverifyRequest
                  isExpanded={false}
                  showReverifyButton={false}
                  doc={docToEdit}
                />
              )}
              <TextField
                sx={{ my: 1 }}
                fullWidth
                value={reverifyNote}
                onChange={(e) => setReverifyNote(e.target.value)}
                label="Reverification note"
                helperText="Optional note to include in the reverify notification"
              />
            </Alert>
          </>
        )}

        <TextField
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
          label={isQAndA ? "Question" : `${entityType} Name`}
          fullWidth
          sx={{ mt: 1, mb: 1 }}
          // inputProps={{ maxLength: MAX_LENGTH }}
        />

        {!isJob && (
          <Box sx={{ my: 2 }}>
            <Uploader
              showSaveCropButton={true}
              onResetPhotos={() => {
                setCoverPhotoUrl("");
              }}
              onPhotosUploadStart={() => setPhotosUploading(true)}
              onPhotosUploaded={({ photoUrls }) => {
                console.log("photos uploaded!!", { photoUrls });
                setPhotosUploading(false);
                setCoverPhotoUrl(photoUrls[0]);
              }}
              uploadButtonText={`${
                docToEdit?.id ? "Edit" : "Add"
              } Document Cover Photo`}
              allowMultipleFiles={false}
              allowCrop={true}
              avatarEditorProps={{
                width: 1200,
                height: 325,
              }}
            />
            {coverPhotoUrl && (
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ maxWidth: "100%" }}
                  src={coverPhotoUrl}
                  alt="group cover"
                />
              </Box>
            )}
          </Box>
        )}

        {!isJob && (
          <AutocompleteTag
            sx={{ my: 2 }}
            network={network}
            value={tags}
            onChange={(tags: WithRef<Tag>[]) => {
              console.log("AutocompeteTag onChange", tags);
              setTags(tags);
            }}
            isQAndA={isQAndA}
          />
        )}

        <AutocompleteFolder
          sx={{ my: 2 }}
          onFolderSelected={(folder) => {
            setFolderInfo(folder);
          }}
          initSelectedSlug={selectedFolderId || docToEdit?.folderPaths[0]}
          authzFilter={{
            hasMinAccessLevel:
              AuthorizedActions[AuthorizedActionTypes.DocsCreate],
          }}
        />
        <Editor
          initialHtml={contentHtml}
          placeholder={`${entityType} Content`}
          onChange={(value: string) => {
            // console.log("set content html", { value });
            setContentHtml(value);
          }}
        />
        <FormControl variant="outlined" fullWidth sx={{ mt: 2, mb: 1 }}>
          <InputLabel htmlFor="repeat-date-outlined-label">
            Verification Frequency
          </InputLabel>
          <Select
            label="Verification Frequency"
            value={frequency}
            onChange={(evt) => setFrequency(evt.target.value as number)}
          >
            <MenuItem value={-1}>Never</MenuItem>
            <MenuItem value={30}>Monthly</MenuItem>
            <MenuItem value={91}>Quarterly</MenuItem>
            <MenuItem value={182}>Every 6 months</MenuItem>
            <MenuItem value={365}>Yearly</MenuItem>
          </Select>
          <FormHelperText>
            How often the {entityType} should be re-verified
          </FormHelperText>
        </FormControl>

        {!isJob && (
          <UserAutocomplete
            network={network}
            selected={docOwners}
            multiple
            onUserSelected={setDocOwners}
            label="Owners*"
            sx={{ width: "100%" }}
            helperText="The set of users who should own this doc. Ownership responsibilities include updating the doc once the verification period is near, and answering any questions on the doc."
          />
        )}

        {!isEmpty(validationErrors) && (
          <Alert sx={{ mt: 2 }} severity="error">
            {validationErrors.map((e) => (
              <div key={e}>{e}</div>
            ))}
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ flexWrap: "wrap" }}>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          startIcon={isReverify ? <PublishedWithChanges /> : <Check />}
          variant="contained"
          loading={isSubmitting || photosUploading}
          onClick={handleSubmit}
        >
          {isReverify ? "Reverify" : docToEdit ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
