import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button, Collapse, Stack } from "@mui/material";
import { useMeasure } from "@reactivers/use-measure";
import { useRef, useState } from "react";

export function ExpandPanel({
  maxCollapsedSize = 0,
  showExpandButton = true,
  children,
  minCollapsedSize = 20,
}: {
  maxCollapsedSize?: number;
  minCollapsedSize?: number;
  showExpandButton?: boolean;
  children: React.ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const contentRef = useRef<HTMLInputElement>(null);
  const contentMeasure = useMeasure({
    ref: contentRef,
    updateOnWindowResize: true,
  });
  // console.log(`contentMeasure ${contentMeasure.height}`, { contentMeasure });

  const shouldShowButton =
    showExpandButton && contentMeasure.height > maxCollapsedSize;

  const collapsedSize = Math.min(contentMeasure.height, maxCollapsedSize);

  return (
    <>
      <Collapse
        // Collapsed size was so small that it results in content being hidden so we set it to minCollapsedSize
        collapsedSize={
          collapsedSize < minCollapsedSize ? minCollapsedSize : collapsedSize
        }
        in={isExpanded}
        timeout="auto"
        unmountOnExit={false}
      >
        <div ref={contentRef}>{children}</div>
      </Collapse>
      {shouldShowButton && (
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            size="small"
            endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? "See Less" : "See More"}
          </Button>
        </Stack>
      )}
    </>
  );
}
