import { Actor } from "@converge-collective/common/models/Actor";
import { WithRef } from "@converge-collective/common/models/Base";
import {
  DocStatuses,
  DocUpdate,
} from "@converge-collective/common/models/DocMeta";
import {
  Group,
  GroupMember,
  liteGroup,
} from "@converge-collective/common/models/Group";
import { Invite, InviteKinds } from "@converge-collective/common/models/Invite";
import {
  Network,
  liteNetwork,
} from "@converge-collective/common/models/Network";
import {
  GroupCreatedPost,
  POST_TYPE,
} from "@converge-collective/common/models/Post";
import {
  LiteProfile,
  NanoProfile,
  Profile,
  liteProfile,
  nanoProfile,
} from "@converge-collective/common/models/Profile";
import { SubCategoryWithCategory } from "@converge-collective/common/models/SubCategory";
import { writeConverter } from "@converge-collective/common/util";
import {
  WriteBatch,
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { isEmpty, kebabCase } from "lodash";
import { converters } from "~/lib/converter";
import { groupPostDetailRoute } from "~/routes";

export type FormData = {
  name: string;
  description: string;
  categories: SubCategoryWithCategory[];
  photoUrl: string;
  colorCode: string;
  profiles?: WithRef<NanoProfile>[];
  joinCode?: string;
  isPrivate: boolean;
};

/**
 * NOTE: if caller provides `providedBatch` it is up to the caller to commit
 * that batch
 */
export const joinGroup = async (
  network: WithRef<Network>,
  group: WithRef<Group>,
  profile: WithRef<Profile | NanoProfile>,
  // optional args
  {
    providedBatch,
    // primary use case is for the current user to join a group. for that we'll
    // also immediately update the user's profile to reflect the new group
    // immediately on the nav, but when adding other members to a group the user
    // won't have permission so we'll let a background fn trigger handle the
    // updates
    handleProfileUpdates = true,
    addedBy,
  }: {
    handleProfileUpdates?: boolean;
    providedBatch?: WriteBatch;
    addedBy?: Actor;
  } = {}
): Promise<void> => {
  try {
    const firestore = network.ref.firestore;
    // Use a batch to write all changes to the database so:
    // - it's atomic
    // - it's fast
    const batch = providedBatch || writeBatch(firestore);

    // 1. add to the group's groupMembers sub collection
    const groupMember: GroupMember = {
      createdAt: new Date(),
      profile: nanoProfile(profile),
      network,
      group: liteGroup(group),
      addedBy,
    };
    batch.set(doc(group.ref, "groupMembers", profile.id), groupMember, {
      merge: true,
    });

    // 2. update the networkMembership for the user to store a copy of the
    // LiteGroup
    if (handleProfileUpdates) {
      batch.set(
        doc(network.ref, "members", profile.id),
        {
          groupMemberships: {
            [group.id]: liteGroup(group),
          },
        },
        { merge: true }
      );
    }

    // if the user provided a batch we let them commit it so they have control
    // over the lifecycle
    return providedBatch ? Promise.resolve() : batch.commit();
  } catch (e) {
    console.error("error in joinGroup", e, { network, group, profile });
    throw e;
  }
};

export const leaveGroup = async (
  network: WithRef<Network>,
  group: WithRef<Group>,
  uid: string
): Promise<void> => {
  // We previously used a batch to leave the group atomically, but if the user
  // was already removed from the network it'd fail the whole batch, so do it in
  // 2 steps:

  // 1. delete the groupMember - a background fn will sync the group members to
  // the `members` attribute of the group.
  try {
    await deleteDoc(doc(group.ref, "groupMembers", uid));
  } catch (e) {
    console.error("error deleting group member", { e, group, uid });
  }
  console.log("deleted member from group", { group, uid });

  // 2. update the networkMembership for the user to store a copy of the
  // LiteGroup
  try {
    await updateDoc(doc(network.ref, "members", uid), {
      [`groupMemberships.${group.id}`]: deleteField(),
    });
  } catch (e) {
    console.error("error updating group member", { e, group, uid });
  }
};

export const createdPost = async (
  network: WithRef<Network>,
  group: WithRef<Group>,
  profile: WithRef<Profile>
): Promise<void> => {
  const url = groupPostDetailRoute(network.slug, group.slug, group.id);
  const postLog: DocUpdate = {
    date: new Date(),
    status: DocStatuses.Active,
    description: `${profile.name} created a new post`,
    actor: liteProfile(profile),
  };
  const groupCreatedPost: GroupCreatedPost = {
    eventType: POST_TYPE.GroupCreated,
    sticky: false,
    network,
    url,
    group: group,
    title: `${profile.name} created a new group: ${group.name}`,
    description: group.descriptionHtml || "New group created",
    date: new Date(),
    avatar: profile.photoURL || "",
    profile: liteProfile(profile),
    latestDocUpdate: postLog,
  };

  // idempotent set
  await setDoc(
    doc(network.ref, "posts", group.id).withConverter(
      writeConverter<GroupCreatedPost>()
    ),
    groupCreatedPost
  );
};

// TODO - use this for creating a group or delete it
export const addNewGroup = async (
  grp: FormData,
  network: WithRef<Network>,
  profile: WithRef<Profile>
): Promise<WithRef<Group>> => {
  const {
    name,
    categories,
    colorCode,
    description,
    photoUrl,
    joinCode,
    isPrivate,
  } = grp;
  const slug = kebabCase(name);
  const now = new Date();

  const groupRef = doc(network.ref, "groups", slug).withConverter(
    converters.group.write
  );

  const docUpdate: DocUpdate = {
    date: now,
    status: DocStatuses.Published,
    description: `${profile.name} created the "${name}" group.`,
    actor: liteProfile(profile),
  };

  const group: Group = {
    ...{
      latestDocUpdate: docUpdate,
      docUpdateLog: [docUpdate],
      name,
      network: liteNetwork(network),
      slug,
      createdBy: liteProfile(profile),
      createdAt: now,
      descriptionHtml: description,
      isStaffLed: false,
      isUserLed: true,
      isPrivate,
      isUnlisted: false,
      isCategoryBased: false,
      leaders: [liteProfile(profile)],
      // These are the categories that the group is interested in. For a
      // category based group this is naturally the category of the group, but
      // for other types of groups it could be any subset of categories, e.g.
      // Tennis, Skiing, and Badminton. When Events are created for those
      // categories, they are automatically posted to the group, which
      // notifies everyone in the group.
      interestedSubCategoryIds: (categories || []).map(
        (scwc) => scwc.subCategory.id
      ),
      interestedSubCategories: (categories || []).map(
        (scwc) => scwc.subCategory
      ),
    },
    ...(!isEmpty(joinCode) && !isPrivate ? { joinCode } : {}),
    ...(!isEmpty(photoUrl) ? { photoUrl } : {}),
    ...(!isEmpty(colorCode) ? { color: colorCode } : {}),
  };

  await setDoc(groupRef, group);
  return { ...group, id: groupRef.id, ref: groupRef, createdAt: now };
};

export const inviteUser = async (
  network: WithRef<Network>,
  group: WithRef<Group>,
  profile: WithRef<LiteProfile>,
  invitee: WithRef<LiteProfile>
) => {
  const invite: Invite = {
    createdAt: new Date(),
    accepted: false,
    url: window.location.href,
    createdBy: liteProfile(profile),
    kind: InviteKinds.JoinGroup,
    group: group,
    invitee,
  };

  await addDoc(
    collection(network.ref, "invites").withConverter(writeConverter<Invite>()),
    invite
  );
};
